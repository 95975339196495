<template>
  <div v-if="isshowgrid">
    <va-card title="Cloud Tv List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" v-if="!(getRole() == 'ADMIN')" @click="pageModification('Create',null)">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="cloud_list"
        :per-page="parseInt(perPage)"
        clickable
      >
      <template slot="actions" slot-scope="props">
        <va-button flat small color="gray" icon="fa fa-pencil" @click="pageModification('update',props.rowData)" class="ma-0"></va-button>
      </template>
      <template slot="action" slot-scope="props">
        <va-button flat small color="red" icon="fa fa-remove" @click="remove(props.rowData)" class="ma-0"></va-button>
      </template>
      </va-data-table>
      <va-modal
          v-model="showRemoveModal"
          title="Delete Cloud Tv"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteCloud(entity)"
          @cancel="cancel()">
        </va-modal>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Channel Name</span>
            <va-input
              v-model="channel_name"
              type="text"
              placeholder="Enter Channel Name"
              :error= '!!channelNameError.length'
              :error-messages = 'channelNameError'
            />
            <span class="va-form-label va-form-required-label">URL</span>
            <va-input
              v-model="hls_url"
              type="text"
              placeholder="Enter Hls Url"
              :error= '!!urlError.length'
              :error-messages = 'urlError'
            />
            <span class="va-form-label va-form-required-label">Logo</span>
            <va-file-upload
              v-model="logo"
              type="single"
              class="mt-2 ml-3"
              @input="checkLogo('horizontal')"
            />
            <div v-if="flag =='Update'">
              <div class="scroll_overflow">
                <div class="ml-4 mt-2" v-if="oldImage">
                  <div><img :src=image_url height="80px" width="80px" /></div>                 
                </div>
              </div>
            </div>
            <div 
              v-if = 'previewLogo'
              class="imagePreviewWrapper mt-2 ml-4" 
              :style="{ 'background-image': `url(${previewLogo})` }">
            </div><br>
            <span class="va-form-label va-form-required-label">LCN</span>
            <va-input
              v-model="lcn"
              type="text"
              placeholder="Enter Lcn"
              oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
              :error = '!!lcnError.length'
              :error-messages = '!!lcnError'
            />
            <span class="va-form-label va-form-required-label">Language</span>
            <va-select
              placeholder="Select Langauge"
              v-model="language"
              textBy="language"
              searchable
              :options="language_list"
              :error = '!!languageError.length'
              :error-messages = "languageError"
            />
            <span class="va-form-label va-form-required-label">Genres</span>
            <va-select
              placeholder="Select Genres"
              v-model="genres"
              textBy="genres"
              searchable
              :options="genres_list"
              :error = '!!genresError.length'
              :error-messages = 'genresError'
            />
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button type="submit" class="my-0" @click.prevent="submit(flag)">{{flag}}</va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import vueResource from 'vue-resource'
  import { search } from '../../i18n/search.js'
  import config from '../../i18n/en.json'
  import REGEX from '../../i18n/regex_pattern'
  Vue.use(vueResource)

  export default {
    name: 'cloud_tv',
    created () {
      this.getCloudTv();
    },
    data () {
      return {
        isshowgrid: true,
        isshowForm: false,
        term: null,
        perPage: '10',
        cloud_list: [],
        flag:'',
        title:'',
        logo:[],
        language_list: [],
        genres_list: [],
        lcn_list: ["51","52","53","54","55","56","57","58","59","60"],
        lcn: '',
        genres: '',
        language: '',
        hls_url: '',
        logoView: false,
        previewLogo: null,
        channel_name:'',
        channelNameError:[],
        urlError:[],
        lcnError: [],
        languageError: [],
        genresError: [],
        oldImage: false,
        image_url:'',
        backup_logo : '',
        channel_id: '',
        showRemoveModal: false,
        msg:''
      };
    },
    computed: {

      formReady () {
        return !this.channelNameError.length && 
               !this.urlError.length && 
               !this.lcnError.length && 
               !this.languageError.length &&
               !this.genresError.length
      },  

      fields () {
        return [
          {
            name: 'id',
            title: 'S.NO'
          }, 
          {
            name: 'channel_name',
            title: 'Channel Name'
          },
          {
            name: 'hls_url',
            title: 'HLS URL',
          },
          {
            name: 'logo',
            title: 'Logo',
          },
          {
            name: 'lcn',
            title: 'LCN',
          },
          {
            name: 'language.language',
            title: 'Language',
          },
          {
            name:'genre.genres',
            title:"Genres"
          },
          {
            name: '__slot:actions',
            dataClass: 'text-right', 
          },
          {
            name: '__slot:action',
            dataClass: 'text-right', 
          }
        ]
      },
      filteredData () {
        return search(this.term, this.cloud_list)
      },
    },
    methods: {

      getCloudTv () {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/cloud_tv').then(cloud_response=> {
          this.$http.get(config.menu.host + '/cloud_tv/language').then(language_response => {
            this.$http.get(config.menu.host + '/cloud_tv/genres').then(genres_response=> {
              this.language_list = language_response.body;
              this.language_list = this.language_list.map((items,index)=>{
                items.id=index+1;
                return items
              });
              loader.hide()
              this.isshowgrid = true
              this.genres_list = genres_response.body;
              this.genres_list = this.genres_list.map((items,index)=>{
                items.id=index+1;
                return items
              });
              this.cloud_list = cloud_response.body
              this.cloud_list = this.cloud_list.map((items,index)=>{
                items.id=index+1;
                return items
              });
            }, error => {
              loader.hide()
              if (error && error.body) {
                Vue.notify({ text: error.body, type: 'error' })
              }
            })
          })
        })
      },
      getRole () {
      return Vue.$cookies.get('userRole')
    },

      checkLogo (type){
        if(this.logo[0] == undefined || this.logo.length == 0){
          this.previewLogo = null
        }
        if(this.logo && this.logo.length> 1){
          this.logo = [this.logo[this.logo.length-1]]
        }
        var logo= this.logo[0]
        if (logo !== undefined) {
          var file_name = logo.name.split('.').pop()
          if (((file_name.toLowerCase()) == ('png' || 'jpg' || 'jpeg' ))){
            const reader = new FileReader()
            reader.readAsDataURL(logo)
            reader.onload = evt => {
              this.oldImage = false
              this.previewLogo = evt.target.result
            }
          }else {
            this.logo = []
            this.previewLogo = null
            this.oldImage = false;
            Vue.notify({ text: 'Please check the image Format', type: 'error' })
          }
        }
      },

      submit (flag) {
        this.channelNameError = this.channel_name ? [] : ['Channel Name is required']
        this.urlError = this.hls_url ? [] : ['Hls Url is required'];
        this.lcnError = this.lcn ? [] : ['Lcn is required'];
        this.languageError = this.language ? [] : ['Language is required']
        this.genresError = this.genres ? [] : ['Genres is required']

        if(!this.formReady) return;

        // if (this.hls_url && !REGEX.hlsUrlRegex(this.hls_url)) {
        //   return Vue.notify({ text: 'Please enter valid Hls Url!', type: 'error' })
        // }

        if(!this.logo[0] && !this.oldImage){
          return Vue.notify({ text: 'Please upload image!', type: 'error' })
        }
        var payload = new FormData()
        payload.append("channel_name",this.channel_name)
        payload.append("hls_url",this.hls_url)
        payload.append("lcn",this.lcn)
        payload.append("language_id",this.language.language_id)
        payload.append("genres",this.genres.genres)
        if(flag == 'Update'){
          if(this.logo[0]!=undefined){
            payload.append('logo',this.logo[0])
            payload.append('old_logo',this.backup_logo)
          }
          var requestUrl = this.$http.put(config.menu.host + '/cloud_tv/'+ this.channel_id, payload);
        } else if(flag == 'Create'){
          payload.append("logo",this.logo[0])
          var requestUrl = this.$http.post(config.menu.host + '/cloud_tv', payload);
        }
        const loader = Vue.$loading.show({ width: 40, height: 40});
        requestUrl.then(response => {
          loader.hide();
          Vue.notify({ text: response.body, type: 'success' });
          this.list();
        },err =>{
          loader.hide();
          if(err && err.body){
            Vue.notify({ text: err.body, type: 'error'});
          };
        });
      },
     
      remove (row) {
        this.msg = 'Are you sure to delete Cloud Tv ' + row.channel_name + ' ?';
        this.entity = row;
        this.showRemoveModal = true;
      },

      cancel () {
        this.showRemoveModal = false;
      },
      deleteCloud (data) {
        this.$http.delete(config.menu.host + '/cloud_tv/'+ data.channel_id+'?old_logo='+data.logo).then(resp => {
          Vue.notify({ text: resp.body, type: 'success' });
          this.list();
        }, err => {
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },

      pageModification(type,row) {
        this.isshowForm = true;
        this.isshowgrid = false;
        if(type == 'Create') {
          this.title = 'Create Cloud TV';
          this.flag = 'Create'
        }else{
          this.title = 'Update Cloud Tv';
          this.flag = 'Update';
          this.oldImage = true;
          this.lcn = row.lcn.toString();
          this.channel_name = row.channel_name;
          this.language = row.language;
          this.genres =  row.genre;
          this.hls_url = row.hls_url;
          this.image_url = 'https://assets.skie.tv/'+row.logo;
          this.backup_logo = row.logo;
          this.logo = [];
          this.channel_id = row.channel_id
        } 
      },

      list () {
        this.flag = '';
        this.title = '';
        this.logo = []
        this.lcn=''
        this.genres = '';
        this.language='';
        this.hls_url='';
        this.logoView = false;
        this.previewLogo = null;
        this.channel_name= '';
        this.channel_id = '';
        this.channelNameError = [];
        this.urlError=[];
        this.languageError =[];
        this.genresError=[];
        this.lcnError=[];
        this.oldImage = false;
        this.image_url = '';
        this.backup_logo= '';
        this.msg ='';
        this.showRemoveModal= false
        this.getCloudTv()
        this.isshowgrid = true
        this.isshowForm = false
      },
      search: function (term) {
        this.term = term
      },
    },
  }
</script>
